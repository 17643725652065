//google settings are defined in the iife argument at the bottom.
//set your client idscopes and api key there.
//config.immediate should be set to true.
var gSheet = (function (settings) {
	'use strict';

	var userTokenMap = {};
	var userProfile;
	var baseURL = _CONFIG.DBK_BASEURL || `//${window.location.host}/`;
	var errorReporter;

	var tokenWatcher;

	return {
		clearTokenMemory: clearTokenMemory,
		setErrorReporter: setErrorReporter,
		settings: settings,
		auth: auth,
		deauthorize: deauthorize,
		getUserProfile: getUserProfile,

		//calendar functions
		calendarList: calendarList,
		eventList: eventList,
		getEvent: getEvent,
		instanceList: instanceList,
		updateEvent: updateEvent,
		createEvent: createEvent,
		deleteEvent: deleteEvent,
		rRule: rRule,
		getRepeatingDates: getRepeatingDates,
		updateArray: updateArray,
		changeCalendar: changeCalendar,
		updateCalendar: updateCalendar,
		updateCalendarList: updateCalendarList,
		ajaxRequest: ajaxRequest,

		//sheet functions
		sheetInfo: sheetInfo,
		sheetRows: sheetRows,
	};

	//Public Functions******************************************

	function clearTokenMemory() {
		settings.token = null;
		settings.config.immediate = false;
	}

	function setErrorReporter(errorReportingFunction) {
		errorReporter = errorReportingFunction;
	}

	function getUserProfile() {
		return userProfile;
	}

	function isSalesforce() {
		return fbk.isSalesforce();
	}

	//Google Authorization routine.
	//Try to get the token silently, if that fails then callback should present button for auth - statusOnly is boolean to avoid an attempt to sign in
	function auth(
		userID,
		sourceID,
		statusOnly,
		forceConsent,
		redirectAuth,
		redirectAuthFunction,
		callback
	) {
		// var forceRedirect = (redirectAuth || isStandalone()) && !isSalesforce();
		var forceRedirect = redirectAuth && !isSalesforce();
		var salesforceMobileRedirect = isMobile() && isSalesforce();
		var scope = 'spreadsheets';

		if (statusOnly) {
			settings.config.immediate = true;
		}

		if (forceConsent) {
			settings.config.immediate = false;
		}

		if (!settings.config.immediate && forceRedirect) {
			authRedirect(sourceID, userID, function (result) {
				check(result, callback);
			});
		}
		// else if (!settings.config.immediate && salesforceMobileRedirect) {
		//Enable this if there is no way to tell the difference between the SF mobile app and the SF1 Web page
		// 	salesforceMobileAuthRedirect(sourceID, userID, callBack);
		// }
		else if (!settings.config.immediate) {
			authPopup(sourceID, userID, function (result) {
				check(result, callback);
			});
		} else if (!settings.token) {
			// Attempt to get new token from server
			updateToken(userID, sourceID, function (result) {
				check(result, callback);
			});
		} else {
			callback(true);
		}

		function authRedirect(sourceID, userID, callback) {
			var currentURL = window.location.href;
			var redirectURL =
				baseURL +
				'api/google/auth?scope=' +
				scope +
				'&sourceID=' +
				encodeURIComponent(sourceID) +
				'&userID=' +
				encodeURIComponent(userID) +
				'&clientRedirectURL=' +
				encodeURIComponent(currentURL);

			if (redirectAuthFunction) {
				redirectURL += '&type=redirectFunction';
				redirectAuthFunction(redirectURL, function () {
					// Wrapped in a timeout because sometimes the read from firebase happens before the data is available.
					// Todo figure out a better way than a delay here to ensure the token is ready to be fetched
					window.setTimeout(() => {
						updateToken(userID, sourceID, function (result) {
							check(result, function (result) {
								callback(result);
							});
						});
					}, 500);
				});
			} else {
				redirectURL += '&type=redirect';
				window.location.href = redirectURL;
			}
		}

		function authPopup(sourceID, userID, callback) {
			var popup = window.open(
				baseURL +
					'api/google/auth?type=popup&scope=' +
					scope +
					'&sourceID=' +
					encodeURIComponent(sourceID) +
					'&userID=' +
					encodeURIComponent(userID),
				'',
				'width=500, height=690'
			);
			popup.onload = function () {
				// Check for an error loading the url
				var documentTitle = popup.document.title;
				if (documentTitle === '502 Bad Gateway') {
					popup.close();
					errorReporter(502);
				}
			};

			popoverCheck(popup, callback);

			function popoverCheck(popupWindow, callback) {
				window.setTimeout(function () {
					var urlParams = {};
					var returnParams = {};
					try {
						urlParams = getURLParams(popupWindow.location.search);
					} catch (error) {
						// Couldn't get url, probably because the popup is currenty showing a google domain
						// Nothing to do here as we will wait for further action either window close or redirect back to our domain
					}
					if (popupWindow && popupWindow.closed) {
						// If window was closed run the callback with no result
						callback();
					} else if (urlParams.access_token) {
						returnParams.access_token = urlParams.access_token;
						returnParams.expiry_date = urlParams.expiry_date;

						popupWindow.close();

						applyToken(userID, sourceID, returnParams, null);

						callback(returnParams);
					} else {
						popoverCheck(popupWindow, callback);
					}
				}, 250);
			}
		}

		function salesforceMobileAuthRedirect(sourceID, userID, callback) {
			var hidden = false;
			var url =
				baseURL +
				'api/google/auth?type=popup&scope=' +
				scope +
				'&sourceID=' +
				encodeURIComponent(sourceID) +
				'&userID=' +
				encodeURIComponent(userID) +
				'&forceClose=true';

			//Add a listener for popup window closing
			document.addEventListener('visibilitychange', onchange);

			//Open new auth window
			fbk.publish('dbk.navigate', {url: url, new: true});

			function onchange(e) {
				hidden = !hidden;
				if (!hidden) {
					document.removeEventListener('visibilitychange', onchange);
					auth(userID, sourceID, true, false, callback);
				}
			}
		}
	}

	function updateToken(userID, sourceID, callback) {
		var params = {
			userID: userID,
			sourceID: sourceID,
		};

		ajaxRequest({
			url: baseURL + 'api/google/token',
			type: 'GET',
			params: params,
			onSuccess: function (tokens) {
				applyToken(userID, sourceID, tokens, callback);
			},
			onError: function (error) {
				console.log(error);
				if (callback) {
					callback(false);
				}
			},
		});
	}

	function applyToken(userID, sourceID, tokens, callback) {
		var fetchMinutesBeforeExpires = 5;
		var now = new Date().getTime();

		var token;
		var expires;

		var updateTimeout;

		// Clear any pending timeouts
		clearTimeout(tokenWatcher);

		if (
			tokens &&
			tokens.access_token &&
			tokens.expiry_date &&
			!tokens.error
		) {
			token = tokens.access_token;
			expires = Number(tokens.expiry_date);

			updateTimeout =
				expires - now - 1000 * 60 * fetchMinutesBeforeExpires;

			// Remove old token from user token map if it exists
			if (userTokenMap[settings.token]) {
				delete userTokenMap[settings.token];
			}

			// Set the token to global settings
			settings.token = token;
			settings.tokenexpires = now + (expires - 60) * 1000;

			userTokenMap[token] = {
				token: token,
				expires: expires,
				userID: userID,
				sourceID: sourceID,
			};

			settings.config.immediate = true;

			// Set a timeout so we can update the token before it expires
			tokenWatcher = window.setTimeout(function () {
				updateToken(userID, sourceID);
			}, updateTimeout);
		} else {
			// Set to true here as we'll be recalling without re-loading the page
			settings.config.immediate = true;
		}

		if (callback) {
			callback(token);
		}
	}

	// callback for checking our silent try
	function check(result, callback) {
		if (result && !result.error) {
			// we're already authorized in.
			userReq(callback);
		} else {
			//set settings.config.immediate to false so next check brings up the pop-over
			//false callBack should trigger showing the auth button
			settings.config.immediate = false;
			if (callback) {
				callback(false);
			}

			return false;
		}
	}

	// // validate our token and get user info on callback
	// function validate(token){

	// 	var params = {
	// 		access_token: token,
	// 	}

	// 	ajaxRequest({
	// 		url: 'https://www.googleapis.com/oauth2/v1/tokeninfo',
	// 		type: 'GET',
	// 		params: params,
	// 		onSuccess: function(response) {
	// 			userReq(response);
	// 		},
	// 		onError: function(error) {
	// 			console.log(error);
	// 		},
	// 	});

	// }

	// get user info
	function userReq(callback) {
		var params = {
			access_token: settings.token,
		};

		ajaxRequest({
			url: 'https://www.googleapis.com/oauth2/v1/userinfo',
			type: 'GET',
			params: params,
			onSuccess: function (response) {
				userProfile = response;
				if (callback) {
					callback(true);
				}
				// timeZone(response, callback);
			},
			onError: function (error) {
				console.log(error);
			},
		});
	}

	function timeZone(response, callback) {
		settings.userinfo = response;

		var params = {
			access_token: settings.token,
		};

		ajaxRequest({
			url: 'https://www.googleapis.com/calendar/v3/users/me/settings/timezone',
			type: 'GET',
			params: params,
			onSuccess: function (response) {
				//load user info
				settings.timezone = response;

				if (callback) {
					callback(true);
				}
			},
			onError: function (error) {
				if (callback) {
					callback(false);
				}
				console.log(error);
			},
		});
	}

	function deauthorize(userID, sourceID, switchAccount, callback) {
		var params = {
			userID: userID,
			sourceID: sourceID,
		};

		if (switchAccount) {
			processDeauthorize(callback);
			return;
		}

		ajaxRequest({
			url: baseURL + 'api/google/token',
			type: 'DELETE',
			params: params,
			onSuccess: function (response) {
				processDeauthorize(callback);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		function processDeauthorize(callback) {
			if (callback) {
				settings.token = null;
				if (!switchAccount) {
					settings.config.immediate = false;
				}
				callback();
			}
		}
	}

	//Public Calendar Functions

	//Load Calendar Lists
	function calendarList(callBack, params, retryCount) {
		//add token to params
		if (!params) {
			params = {access_token: settings.token};
		} else {
			params.access_token = settings.token;
		}

		var params = {
			access_token: settings.token,
		};

		ajaxRequest({
			url:
				'https://www.googleapis.com/calendar/' +
				settings.calendarApi +
				'/users/me/calendarList',
			type: 'GET',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		//Process response result and activate retry if necessary
		function processResponse(result) {
			if (callBack) {
				callBack(result);
			}
		}
	}

	//get specified event. Used for getting original event from repetition series.
	function getEvent(calendarId, eventId, params, callback) {
		//add token to params
		if (!params) {
			params = {access_token: settings.token};
		} else {
			params.access_token = settings.token;
		}

		ajaxRequest({
			url:
				'https://www.googleapis.com/calendar/' +
				settings.calendarApi +
				'/calendars/' +
				encodeURIComponent(calendarId) +
				'/events/' +
				encodeURIComponent(eventId),
			type: 'GET',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		function processResponse(data) {
			callback(data);
		}
	}

	function buildRanges(fieldMap, sheetName, firstDataRow, lastDataRow) {
		var columns = [];
		var fields = [];
		var field;
		var columnLetter;
		var lowest;
		var highest;
		var result;

		var sheetNameString = sheetName ? "'" + sheetName + "'" + '!' : '';
		for (var property in fieldMap) {
			field = fieldMap[property];
			if (!field || field === '') {
				continue;
			}
			field = letterToColumn(field);

			if (isNaN(field)) {
				continue;
			}

			if (highest === undefined || field > highest) {
				fields.push(property);
				highest = field;
			}
			if (lowest === undefined || field < lowest) {
				fields.unshift(property);
				lowest = field;
			}
		}

		var result = {
			fields: fields,
			range:
				sheetNameString +
				columnToLetter(lowest) +
				firstDataRow +
				':' +
				columnToLetter(highest) +
				lastDataRow,
			lowest: lowest,
			highest: highest,
		};
		return result;
	}

	function cleanColumnLetter(letter) {
		if (letter) {
			return letter.toUpperCase();
		}
		return '';
	}

	function columnToLetter(column) {
		var temp,
			letter = '';
		while (column >= 0) {
			temp = column % 26;
			letter = String.fromCharCode(temp + 65) + letter;
			column = (column - temp - 1) / 26;
		}
		return letter;
	}

	function letterToColumn(letter) {
		if (!letter || letter.length > 2) {
			return NaN;
		}
		var column = 0,
			length = letter.length;
		for (var i = 0; i < length; i++) {
			column +=
				(letter.toUpperCase().charCodeAt(i) - 64) *
				Math.pow(26, length - i - 1);
		}
		return column - 1;
	}

	//Load Events Lists
	function eventList(
		sheetID,
		sheetName,
		fieldMap,
		schedule,
		callBack,
		params,
		retryCount
	) {
		//add token to params
		if (!params) {
			params = {access_token: settings.token};
		} else {
			params.access_token = settings.token;
		}

		var firstDataRow = schedule.firstDataRow;
		var lastDataRow = schedule.lastDataRow;

		var rangeResult = buildRanges(
			fieldMap,
			sheetName,
			firstDataRow,
			lastDataRow
		);
		// params.ranges = rangeResult.range;//ranges.columns;

		// params.fields = 'properties.title,sheets(properties,data.rowData.values(userEnteredValue,formattedValue,effectiveFormat))';

		// params.includeGridData = true;

		params.majorDimension = 'ROWS';
		params.valueRenderOption = 'UNFORMATTED_VALUE';
		params.dateTimeRenderOption = 'FORMATTED_STRING';

		ajaxRequest({
			url:
				'https://sheets.googleapis.com/' +
				settings.calendarApi +
				'/spreadsheets/' +
				encodeURIComponent(sheetID) +
				'/values/' +
				rangeResult.range,
			type: 'GET',
			params: params,
			forcePreventDeauth: true,
			errorReportName: schedule.name,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				checkResult(response);
			},
			onError: function (error) {
				console.log(error);
				checkResult({error: error});
			},
		});

		//internal call back to get more results.
		function checkResult(result) {
			var items = [];
			processResult(result);

			function processResult(result) {
				//Exit with callback if we have an error
				if (result.error) {
					if (callBack) {
						callBack(result);
					}
					return;
				} else {
					items = eventsFromValues(
						result.values,
						fieldMap,
						firstDataRow,
						rangeResult.lowest,
						schedule.id
					);

					callBack(items);
				}
			}
		}
	}

	function eventsFromValues(
		values,
		fieldMap,
		firstDataRow,
		startColumn,
		idPrefix
	) {
		var events = [];
		var event;
		var fieldIndex;

		if (!values) {
			return events;
		}

		for (var i = 0; i < values.length; i++) {
			event = {};

			for (var property in fieldMap) {
				fieldIndex = fieldMap[property];
				if (!fieldIndex || fieldIndex === '') {
					continue;
				}

				fieldIndex = letterToColumn(fieldIndex);

				if (isNaN(fieldIndex)) {
					continue;
				}

				fieldIndex = fieldIndex - startColumn;

				event[property] = values[i][fieldIndex];
			}
			if (!event.eventID) {
				event.eventID = idPrefix + '-' + (i + firstDataRow);
			}
			if (event.start) {
				events.push(event);
			}
		}

		return events;
	}

	function eventsFromSheet(result) {
		var rowData;
		var data;
		var event;
		var fieldIndex;
		var fieldFormat;
		var startColumn;

		// We can probably delete this
		for (var i = 0; i <= 0; i++) {
			// only support 1 sheet right now
			data = result.sheets[i].data;

			for (var ii = 0; ii < data.length; ii++) {
				startColumn = data[ii].startColumn || 0;

				rowData = data[ii].rowData;
				for (var iii = 0; iii < rowData.length; iii++) {
					event = {};

					for (var property in fieldMap) {
						fieldIndex = fieldMap[property];

						if (!fieldIndex || fieldIndex === '') {
							continue;
						}

						fieldIndex = letterToColumn(fieldIndex);

						if (isNaN(fieldIndex)) {
							continue;
						}

						fieldIndex = fieldIndex - startColumn;

						if (
							rowData[iii] &&
							rowData[iii].values &&
							rowData[iii].values[fieldIndex] &&
							rowData[iii].values[fieldIndex].formattedValue
						) {
							fieldFormat =
								rowData[iii].values[fieldIndex].effectiveFormat;

							if (
								fieldFormat.numberFormat &&
								fieldFormat.numberFormat.type === 'NUMBER'
							) {
								var effectiveValue =
									rowData[iii].values[fieldIndex]
										.effectiveValue;
								if (effectiveValue) {
									event[property] =
										effectiveValue.numberValue;
								}
							} else {
								event[property] =
									rowData[iii].values[
										fieldIndex
									].formattedValue;
							}

							// Normalize all day
							if (
								property === 'start' &&
								fieldFormat &&
								fieldFormat.numberFormat
							) {
								if (fieldFormat.numberFormat.type === 'DATE') {
									event.allDay = true;
								} else {
									event.allDay = false;
								}
							}
						}
					}
					if (!event.eventID) {
						event.eventID = firstDataRow + iii;
					}
					items.push(event);
				}
			}
		}
		return items;
	}

	function eventsFromFilter(data, fieldList) {
		var events = [];
		var rowData;
		var fieldData;
		var field;
		var fieldFormat;
		for (var i = 0; i < data.length; i++) {
			if (!data[i]) {
				continue;
			}
			rowData = data[i].rowData;
			if (!rowData) {
				continue;
			}

			for (var ii = 0; ii < rowData.length; ii++) {
				if (!events[ii]) {
					events[ii] = {
						eventID: i,
					};
				}
				fieldData = rowData[ii].values[0];

				// Set field
				events[ii][field] = rowData[ii].values[0].formattedValue;

				field = fieldList[i];

				fieldFormat = rowData[ii].values[0].effectiveFormat;

				// Normalize all day
				if (
					field === 'start' &&
					fieldFormat &&
					fieldFormat.numberFormat
				) {
					if (fieldFormat.numberFormat.type === 'DATE') {
						events[ii].allDay = true;
					} else {
						events[ii].allDay = false;
					}
				}
			}
		}
		return events;
	}

	//Load Events Lists
	function instanceList(
		calendarId,
		eventId,
		fieldMap,
		callBack,
		params,
		retryCount
	) {
		//using a custom result
		if (settings.mapIn) {
			var resultClass = settings.mapIn;
		}

		//add token to params
		if (!params) {
			params = {access_token: settings.token};
		} else {
			params.access_token = settings.token;
		}

		ajaxRequest({
			url:
				'https://www.googleapis.com/calendar/' +
				settings.calendarApi +
				'/calendars/' +
				encodeURIComponent(calendarId) +
				'/events/' +
				eventId +
				'/instances',
			type: 'GET',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				checkResult(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		//internal call back to get more results.
		function checkResult(result) {
			var items = [];
			processResult(result);

			function processResult(result) {
				//Exit with callback if we have an error
				if (result.error) {
					if (callBack) {
						callBack(result);
					}
					return;
				}

				if (resultClass && settings.mapsOn === true) {
					for (var i = 0; i < result.items.length; i++) {
						items.push(
							transformIn(result.items[i], resultClass, fieldMap)
						);
					}
				} else {
					for (var i = 0; i < result.items.length; i++) {
						items.push(result.items[i]);
					}
				}

				//we won't need this, except in horizon view?
				if (result.nextPageToken) {
					// we have more results to get
					params.pageToken = result.nextPageToken;

					ajaxRequest({
						url:
							'https://www.googleapis.com/calendar/' +
							settings.calendarApi +
							'/calendars/' +
							encodeURIComponent(calendarId) +
							'/events/' +
							eventId +
							'/instances',
						type: 'GET',
						params: params,
						onSuccess: function (response) {
							processResult(response);
						},
						onError: function (error) {
							console.log(error);
						},
					});
				} else {
					result.items = items;
					callBack(result); // external callback
				}
			}
		}

		function newObject() {
			var o = {};
			return o;
		}
	}

	//Utility for updating array with array of new/edited objects
	function updateArray(targetArray, newObject, deleteCancelled) {
		//delete canelled events id the default
		if (deleteCancelled === undefined) {
			var deleteCancelled = true;
		}
		//find old version of event object and update
		var i = 0;
		for (i in targetArray) {
			if (targetArray[i]['id'] === newObject['id']) {
				//if the event has been cancelled and deleteCancelled, then splice out of array.
				if (deleteCancelled && newObject['status'] === 'cancelled') {
					targetArray.splice(i, 1);
					return newObject['status'];
				}
				targetArray[i] = newObject;
				return newObject['id'];
			}
		}
		//no old object found, so add to our array.
		targetArray.push(newObject);
		return newObject['id'];
	}

	//Update Event
	function updateEvent(
		calendarId,
		eventId,
		request,
		eventData,
		fieldMap,
		callBack,
		params,
		retryCount,
		explodeUntil
	) {
		//using a custom result map
		if (settings.mapIn) {
			var resultClass = settings.mapIn;
		}

		//using a custom request map
		if (settings.mapOut && settings.mapsOn === true && !retryCount) {
			request = transformOut(request, eventData, settings.mapOut);
		}
		//add token to query
		if (!params) {
			params = {access_token: settings.token};
		} else {
			params.access_token = settings.token;
		}

		ajaxRequest({
			url:
				'https://www.googleapis.com/calendar/' +
				settings.calendarApi +
				'/calendars/' +
				encodeURIComponent(calendarId) +
				'/events/' +
				encodeURIComponent(eventId),
			type: 'PATCH',
			params: params,
			data: request,
			retryCheck: retryCheck,
			preventErrorReporter: true,
			onSuccess: function (response) {
				getInstances(response);
			},
			onError: function (error) {
				if (error && error.preventDeauth) {
					getInstances({error: error});
				}
			},
		});

		//internal callback to create instances.
		function getInstances(result) {
			//Exit with callback if we have an error
			if (result.error) {
				if (callBack) {
					callBack(result);
				}
				return;
			}

			if (explodeUntil) {
				//build request with timeMax
				instanceList(
					calendarId,
					result.id,
					fieldMap,
					processInstances,
					{timeMax: explodeUntil.format()}
				);
			} else {
				if (resultClass && settings.mapsOn === true && !explodeUntil) {
					callBack(transformIn(result, resultClass, fieldMap));
				} else {
					callBack(result);
				}
			}
			function processInstances(result) {
				callBack(result);
			}
		}
	}

	//Update Event
	function createEvent(
		calendarId,
		request,
		eventData,
		fieldMap,
		callBack,
		params,
		explodeUntil,
		retryCount
	) {
		//using a custom result
		if (settings.mapIn) {
			var resultClass = settings.mapIn;
		}
		//using a custom request map
		if (settings.mapOut && settings.mapsOn === true && !retryCount) {
			request = transformOut(request, eventData, settings.mapOut);
		}

		//add token to query
		if (!params) {
			params = {access_token: settings.token};
		} else {
			params.access_token = settings.token;
		}

		ajaxRequest({
			url:
				'https://www.googleapis.com/calendar/' +
				settings.calendarApi +
				'/calendars/' +
				encodeURIComponent(calendarId) +
				'/events/',
			type: 'POST',
			params: params,
			data: request,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				getInstances(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		//internal callback to create instances.
		function getInstances(result) {
			//Exit with callback if we have an error
			if (result.error) {
				if (callBack) {
					callBack(result);
				}
				return;
			}

			if (explodeUntil) {
				//build request with timeMax
				instanceList(
					calendarId,
					result.id,
					fieldMap,
					processInstances,
					{timeMax: explodeUntil.format()}
				);
			} else {
				if (resultClass && settings.mapsOn === true && !explodeUntil) {
					callBack(transformIn(result, resultClass, fieldMap));
				} else {
					callBack(result);
				}
			}
			function processInstances(result) {
				callBack(result);
			}
		}
	}

	//appends/formats an existing request with a repeating rule based on a simple rule object
	function rRule(request, rule) {
		//get timezone settings
		var tz = settings.timezone.value;
		// is there a time for this event, of so we need to populate the timezone field in request
		if (settings.mapsOn === true) {
			//custom mapping
			//our map needs to handle the timezone mapping, but we do need the timezone from here
			request.timezone = tz;
			if (request.allDay === true) {
				var time = false;
			} else {
				var time = true;
			}
		} else {
			//google request
			var time = false;
			var s = request.start;
			var e = request.end;
			if (s.dateTime) {
				time = true;
				if (s.timeZone) {
					tz = s.timeZone;
				}
				s.timeZone = tz;
				e.timeZone = tz;
				s.dateTime = moment.tz(s.dateTime, tz).format();
				e.dateTime = moment.tz(e.dateTime, tz).format();
			}
		}

		//clean up our UNTIL parameter if passed, must be formatted correctly
		if (rule.until) {
			if (time === true) {
				var t = moment
					.tz(rule.until, tz)
					.toISOString()
					.replace(/-/g, '')
					.replace(/:/g, '')
					.replace(/\./g, '');
				t = t.substring(0, 13) + t.substring(16);
				rule.until = t;
			} else {
				rule.until = moment(rule.until).format('YYYYMMDD');
			}
		}
		//now build our rule string.
		var freq = rule['frequency'].toUpperCase();
		var resultArray = [];
		var result = shared(rule);
		if (freq === 'WEEKLY') {
			if (rule['days']) {
				result += arrayDays(rule['days']);
			}
		}
		if (freq === 'MONTHLY') {
			if (rule['days']) {
				result += arrayDays(rule['days']);
			} else if (rule['dayNumbers']) {
				result += ';BYMONTHDAY=' + rule['dayNumbers'].toString();
			}
		}
		if (freq === 'YEARLY') {
			result += ';BYMONTH=' + rule.monthNumbers.toString();
			result += ';BYMONTHDAY=' + rule.dayNumbers.toString();
		}
		//build final reule string and push to our array.
		result = 'RRULE:' + result;
		resultArray.push(result);

		//do we have exceptions?
		if (rule['exceptions']) {
			resultArray.push(exceptions(rule['exceptions']));
		}

		request.recurrence = resultArray;
		return resultArray;

		//helper functions for rRule
		function arrayDays(days) {
			var d = 0;
			var thisDay = '';
			var result = ';BYDAY=';
			for (d in days) {
				thisDay = days[d].toUpperCase();
				if (thisDay.indexOf('FIRST') != -1) {
					result += '1' + isolateDay(thisDay);
				} else if (thisDay.indexOf('SECOND') != -1) {
					result += '2' + isolateDay(thisDay);
				} else if (thisDay.indexOf('THIRD') != -1) {
					result += '3' + isolateDay(thisDay);
				} else if (thisDay.indexOf('FOURTH') != -1) {
					result += '4' + isolateDay(thisDay);
				} else if (thisDay.indexOf('LAST') != -1) {
					result += '-1' + isolateDay(thisDay);
				} else {
					result += isolateDay(thisDay);
				}
				if (d != days.length - 1) {
					result += ',';
				}
			}
			return result;
		}

		function shared(params) {
			var result = 'FREQ=' + freq;
			if (params['count']) {
				//result += ";COUNT=" + params["count"];
			} else if (params['until']) {
				result += ';UNTIL=' + params['until'];
			}
			if (params['interval']) {
				result += ';INTERVAL=' + params['interval'];
			}
			return result;
		}

		function isolateDay(val) {
			if (val.indexOf('MONDAY') != -1) {
				return 'MO';
			} else if (val.indexOf('TUESDAY') != -1) {
				return 'TU';
			} else if (val.indexOf('WEDNESDAY') != -1) {
				return 'WE';
			} else if (val.indexOf('THURSDAY') != -1) {
				return 'TH';
			} else if (val.indexOf('FRIDAY') != -1) {
				return 'FR';
			} else if (val.indexOf('SATURDAY') != -1) {
				return 'SA';
			} else if (val.indexOf('SUNDAY') != -1) {
				return 'SU';
			}
		}

		function exceptions(a) {
			var result = [];
			var c = 0;
			for (c in a) {
				result[c] = moment.tz(a[c], tz).format('YYYYMMDDTHHmmss');
			}
			return 'EXDATE;TZID=' + tz + ':' + result.toString();
		}
	}

	function updateCalendarList(calendarId, request, callBack, retryCount) {
		var params = {};

		//add token to params
		params = {access_token: settings.token};

		if (request['backgroundColor']) {
			if (request['backgroundColor'].length) {
				params['colorRgbFormat'] = true;
			} else {
				params['colorRgbFormat'] = false;
			}
		}

		ajaxRequest({
			url:
				'https://www.googleapis.com/calendar/' +
				settings.calendarApi +
				'/users/me/calendarList/' +
				encodeURIComponent(calendarId),
			type: 'PATCH',
			params: params,
			data: request,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		//Process response result and activate retry if necessary
		function processResponse(result) {
			if (callBack) {
				callBack(result);
			}
		}
	}

	function updateCalendar(calendarId, request, callBack, retryCount) {
		//add token to params
		var params = {access_token: settings.token};

		ajaxRequest({
			url:
				'https://www.googleapis.com/calendar/' +
				settings.calendarApi +
				'/calendars/' +
				encodeURIComponent(calendarId),
			type: 'PATCH',
			params: params,
			data: request,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		//Process responce result and activate retry if necessary
		function processResponse(result) {
			if (callBack) {
				callBack(result);
			}
		}
	}

	//change the event a calendar is attaches to.
	function changeCalendar(
		eventId,
		calendarId,
		newCalendarId,
		fieldMap,
		callBack,
		retryCount
	) {
		//using a custom result
		if (settings.mapIn) {
			var resultClass = settings.mapIn;
		}

		var params = {
			access_token: settings.token,
			destination: newCalendarId,
		};

		ajaxRequest({
			url:
				'https://www.googleapis.com/calendar/' +
				settings.calendarApi +
				'/calendars/' +
				encodeURIComponent(calendarId) +
				'/events/' +
				encodeURIComponent(eventId) +
				'/move',
			type: 'POST',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		//Process response result and activate retry if necessary
		function processResponse(result) {
			//Exit with callback if we have an error
			if (result.error) {
				callBack(result);
				return;
			}

			if (resultClass && settings.mapsOn === true) {
				callBack(transformIn(result, resultClass, fieldMap));
			} else {
				callBack(result);
			}
		}
	}

	//delete the specified event
	function deleteEvent(calendarId, eventId, callBack, retryCount) {
		var params = {
			access_token: settings.token,
		};

		ajaxRequest({
			url:
				'https://www.googleapis.com/calendar/' +
				settings.calendarApi +
				'/calendars/' +
				encodeURIComponent(calendarId) +
				'/events/' +
				encodeURIComponent(eventId),
			type: 'DELETE',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		//Process response result and activate retry if necessary
		function processResponse(result) {
			if (callBack) {
				callBack(result);
			}
		}
	}

	//creates a dummy event so we can explode the instances and conver to simple objects with start and end properties, dummy event is deleted when results are completed
	function getRepeatingDates(calendarId, request, rule, fieldMap, callBack) {
		settings.mapsOff = true;

		var newResult = [];
		var instances = [];
		var token = [];

		request = rRule(request, rule);

		createEvent(calendarId, request, createCallBack);

		function createCallBack(result) {
			var eventId = result.id;
			instanceList(calendarId, eventId, fieldMap, instanceCallBack);
		}
		function instanceCallBack(result) {
			var eventId = result.items[0].recurringEventId;
			newResult = getDates(result.items);
			callBack(newResult);
			if (!result.nextPageToken) {
				//no more results, we can delete our dummy
				deleteEvent(calendarId, eventId, deleteCallBack);
			}
		}
		function deleteCallBack(result) {
			if (result) {
				console.log(result);
			}
			settings.mapsOff = false;
		}
	}

	function retryCheck(result) {
		console.log('gsheet error result', result);
		if (!result) {
			return;
		}
		var error = result.error;
		var errorResult;
		var reAuth;
		var message;
		var status;
		var preventDeauth;

		if (error) {
			status = error.status; // When a token is expired will be set to 'UNAUTHENTICATED'
			message = error.message;
			reAuth = Number(error.code) === 401 || Number(error.code) === 403;
			preventDeauth = !reAuth;

			errorResult = {
				reAuth: reAuth,
				preventDeauth: preventDeauth,
				code: error ? Number(error.code) : null,
				message: message ? message : '',
			};
		}

		return errorResult;
	}

	//Private Calendar Functions******************************************
	function ajaxRequest(options, retryCount) {
		// parameter object options:
		//{url, type, params, data, retryCheck, preventErrorReporter, onSuccess, onError}

		var responseResult;
		var paramList = [];
		var data;
		var type;
		var retryCheckResult;
		var url = options.url;
		var code;
		var message;
		var preventDeauth;

		var errorReportMessageAddition = options.errorReportName
			? ' for ' + options.errorReportName
			: '';

		if (!options) {
			return;
		}

		if (!retryCount) {
			retryCount = 0;
		}

		// Build parameter string and append to url
		if (options.params) {
			url += '?';

			for (var param in options.params) {
				if (Array.isArray(options.params[param])) {
					for (var i = 0; i < options.params[param].length; i++) {
						paramList.push(
							encodeURIComponent(param) +
								'=' +
								encodeURIComponent(options.params[param][i])
						);
					}
				} else {
					paramList.push(
						encodeURIComponent(param) +
							'=' +
							encodeURIComponent(options.params[param])
					);
				}
			}

			url += paramList.join('&');
		}

		type = options.type ? options.type.toUpperCase() : 'GET';

		var xhr = new XMLHttpRequest();
		xhr.open(type, url);

		if (options.data) {
			try {
				data = JSON.stringify(options.data);
				xhr.setRequestHeader('Content-Type', 'application/json');
			} catch (error) {
				// Could perform an action if options is not an object
				data = options.data;
				xhr.setRequestHeader(
					'Content-Type',
					'application/x-www-form-urlencoded'
				);
			}
		}

		xhr.onreadystatechange = function (e) {
			if (xhr.readyState == 4) {
				try {
					responseResult = JSON.parse(xhr.response);
				} catch (error) {
					responseResult = xhr.response;
				}

				if (
					xhr.status == 200 ||
					(type === 'DELETE' && xhr.status == 204)
				) {
					if (
						options.onSuccess &&
						(xhr.response ||
							(type === 'DELETE' && xhr.status == 204))
					) {
						retryCheckResult = options.retryCheck
							? options.retryCheck(responseResult)
							: null;

						if (
							retryCheckResult &&
							retryCount < settings.retryLimit
						) {
							if (retryCheckResult.reAuth) {
								authAndRetry();
							} else {
								retry();
							}

							return;
						}

						options.onSuccess(responseResult);
					}
				} else {
					retryCheckResult = options.retryCheck
						? options.retryCheck(responseResult)
						: null;
					preventDeauth =
						options.forcePreventDeauth ||
						(retryCheckResult && retryCheckResult.preventDeauth);

					// Check for an invalid token response and attempt to renew the token. Limit to 3 retries
					if (
						((retryCheckResult && retryCheckResult.reAuth) ||
							(options.params.access_token &&
								!retryCheckResult &&
								(Number(xhr.status) === 401 ||
									Number(xhr.status) === 403))) &&
						retryCount < settings.retryLimit &&
						userTokenMap[settings.token]
					) {
						authAndRetry();
					} else if (
						retryCheckResult &&
						retryCount < settings.retryLimit
					) {
						retry();
					} else if (options.onError) {
						if (
							(options.preventErrorReporter && !preventDeauth) ||
							!options.preventErrorReporter
						) {
							errorReporter(
								xhr.status,
								(retryCheckResult && retryCheckResult.message) +
									errorReportMessageAddition,
								preventDeauth
							);
						}
						options.onError(
							retryCheckResult || xhr.response,
							xhr.status,
							preventDeauth
						);
					}
				}
			}
		};
		xhr.send(data);

		function authAndRetry() {
			window.setTimeout(function () {
				updateToken(
					userTokenMap[settings.token].userID,
					userTokenMap[settings.token].sourceID,
					function (token) {
						options.params.access_token = token;
						ajaxRequest(options, retryCount + 1);
					}
				);
			}, settings.retryWait);
		}

		function retry() {
			window.setTimeout(function () {
				ajaxRequest(options, retryCount + 1);
			}, settings.retryWait);
		}
	}

	function getURLParams(url) {
		if (!url) {
			return {};
		}
		var match,
			pl = /\+/g, // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) {
				return decodeURIComponent(s.replace(pl, ' '));
			},
			query = url.substring(1);

		var urlParams = {};
		while ((match = search.exec(query))) {
			urlParams[decode(match[1])] = decode(match[2]);
		}
		return urlParams;
	}

	function isStandalone() {
		var standalone =
			'standalone' in window.navigator && window.navigator.standalone;
		return standalone;
	}

	function isIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	function isMobile() {
		return isIos() || isAndroid() || isWindowsPhone();
	}

	function isIos() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /iphone|ipod|ipad/.test(userAgent) && !window.MSStream;
	}

	function isAndroid() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /android/i.test(userAgent);
	}

	function isWindowsPhone() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /windows phone/i.test(userAgent);
	}

	//we either get one object back or an object with an "items" array if instances,
	function transformIn(result, resultClass, fieldMap) {
		var newObject = {};
		for (var property in fieldMap) {
			try {
				if (resultClass[property]) {
					newObject[property] =
						resultClass[property].getValue(result);
				} else {
					newObject[property] = resultClass.custom.getValue(
						result,
						fieldMap[property]
					);
				}
			} catch (error) {
				errorReporter(error, property + ' - ' + error);
			}
		}

		try {
			//Assign google specific items
			newObject.colorId = resultClass.colorId.getValue(result);
		} catch (error) {
			errorReporter(error, property + ' - ' + error);
		}

		return newObject;
	}

	function getSharedPropertyType(event) {
		var type = 'private';
		var attendees = event && event.attendees ? event.attendees : [];

		if (attendees && attendees.length) {
			for (var i = 0; i < attendees.length; i++) {
				if (attendees[i].self && attendees[i].organizer) {
					type = 'shared';
					break;
				}
			}
		} else {
			type = 'shared';
		}

		return type;
	}

	//function for mapping our properties to google's for the request
	function transformOut(request, eventData, mapOut) {
		var mapObj = {};
		var newRequest = {};
		var property = '';
		var value = '';

		var sharedPropertyType = getSharedPropertyType(eventData);

		for (var prop in request) {
			try {
				if (mapOut[prop]) {
					mapObj = mapOut[prop](
						request,
						eventData,
						sharedPropertyType
					);
				} else {
					mapObj = mapOut.custom(request, prop, sharedPropertyType);
				}
			} catch (error) {
				errorReporter(error, JSON.stringify(i) + ' - ' + error);
			}
			property = mapObj['property'];
			value = mapObj['value'];
			if (typeof value !== 'object') {
				//not an array or object
				newRequest[property] = value;
			} else if (value instanceof Array) {
				//an array, so we can write to this level
				newRequest[property] = value;
			} else if (!newRequest[property]) {
				//property doesn't exist yet, so we can write our tree to it.
				newRequest[property] = value;
			} else {
				//property is an object, so we need to run our patch routine
				newRequest[property] = patchObject(newRequest[property], value);
			}
		}
		return newRequest;
	}

	//function for patching an object so we pass nested objects in our mapOut and not overwrite.
	function patchObject(target, patch) {
		var patchProps = Object.getOwnPropertyNames(patch);
		var c = 0;
		var thisProp = '';
		var value = '';
		var property = '';
		for (c in patchProps) {
			value = patch[patchProps[c]];
			property = patchProps[c];
			if (typeof value !== 'object') {
				//not an array or object
				target[property] = value;
			} else if (value instanceof Array) {
				//an array, so we can write to this level
				target[property] = value;
			} else if (!target[property]) {
				//property doesn't exist yet, so we can write our tree to it.
				target[property] = value;
			} else {
				//property is an object, so we need to run our patch routine
				target[property] = patchObject(target[property], value);
			}
		}
		return target;
	}

	//creates objects with just a start and end time based on a google event object
	function getDates(gArray) {
		function newObj() {
			var obj = {};
			return obj;
		}
		var result = [];
		var c = 0;
		for (c in gArray) {
			result[c] = newObj();
			if (gArray[c].start.date) {
				//just the date
				result[c].start = gArray[c].start.date;
			} else if (gArray[c].start.dateTime) {
				//date Time
				result[c].start = gArray[c].start.dateTime;
			}
			if (gArray[c].end.date) {
				//just the date
				result[c].end = gArray[c].end.date;
			} else if (gArray[c].end.dateTime) {
				//date Time
				result[c].end = gArray[c].end.dateTime;
			}
		}
		return result;
	}

	//checks if our token is close to expiration before we attempt a call.
	function tokenOk() {
		//if our token has expired, then re-run the auth with no callBack
		var ts = new Date().getTime();
		if (Number(ts) >= Number(settings.tokenexpires)) {
			return false;
		} else {
			return true;
		}
	}

	//Public Sheet Functions******************************************

	//get available spreadsheets for this account
	//worksheets added as nested arrays
	function sheetInfo(callBack) {
		var url =
			'https://spreadsheets.google.com/feeds/spreadsheets/private/full?access_token=' +
			settings.token;
		var xmlhttp = new XMLHttpRequest();
		xmlhttp.onreadystatechange = function () {
			if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
				//update settings and save our external callBack.
				settings.spreadsheetList = sheetXML2JS(xmlhttp.responseXML);
				settings.callBack = callBack;
				appendWorkSheets(); //this cycles and will do the final external callBack when complete.
			}
		};
		xmlhttp.open('GET', url, true);
		xmlhttp.send();

		//convert the initial worksheet feed to JS objects.
		function sheetXML2JS(xml) {
			var result = [];
			function newObject(line) {
				var result = {};
				var val = '';
				var data =
					line.getElementsByTagName('title')[0].childNodes[0].data;
				if (data) {
					val = data.nodeValue;
				} else {
					val = '';
				}
				result['title'] = data;
				var data =
					line.getElementsByTagName('content')[0].childNodes[0].data;
				if (data) {
					val = data.nodeValue;
				} else {
					val = '';
				}
				result['content'] = data;
				var data = line
					.getElementsByTagName('author')[0]
					.getElementsByTagName('name')[0].childNodes[0].data;
				if (data) {
					val = data.nodeValue;
				} else {
					val = '';
				}
				result['author'] = data;
				var data = line
					.getElementsByTagName('author')[0]
					.getElementsByTagName('email')[0].childNodes[0].data;
				if (data) {
					val = data.nodeValue;
				} else {
					val = '';
				}
				result['email'] = data;
				var data =
					line.getElementsByTagName('updated')[0].childNodes[0].data;
				if (data) {
					val = data.nodeValue;
				} else {
					val = '';
				}
				result['updated'] = data;
				var link = line
					.getElementsByTagName('link')[0]
					.getAttribute('href');
				result['link'] = link;
				result['worksheets'] = []; //initialize the array
				return result;
			}
			var entries = xml.getElementsByTagName('entry');
			var numEntries = entries.length;
			var c = 0;
			while (c < numEntries) {
				result[c] = newObject(entries[c]);
				c++;
			}
			return result;
		}

		//cycle through the Spreadsheets, doing a JSONP call until all worksheets are populated.
		function appendWorkSheets() {
			var c = 0; //used in enclosure check Result.
			for (c in settings.spreadsheetList) {
				if (settings.spreadsheetList[c]['worksheets'].length === 0) {
					var url = settings.spreadsheetList[c]['link'];
					jsonpSend(url, null, checkResult);
					return true;
				}
			}

			settings.callBack(settings.spreadsheetList);
			settings.callBack = null;
			settings.internalCallBack = null;

			function checkResult(result) {
				var entry = result.feed.entry;
				var i = 0;
				for (i in entry) {
					settings.spreadsheetList[c]['worksheets'].push(
						wSheets2Js(entry[i])
					);
				}
				if (result) {
					appendWorkSheets();
				}
			}
		}

		//trim/flatten the JSON response
		function wSheets2Js(line) {
			var result = {};

			result['title'] = line.title.$t;
			result['updated'] = line.updated.$t;
			result['columnCount'] = line.gs$colCount.$t;
			result['rowCount'] = line.gs$rowCount.$t;
			result['rowFeed'] = line.link[0].href;
			result['cellFeed'] = line.link[1].href;
			result['visualAPI'] = line.link[2].href;
			result['exportCSV'] = line.link[3].href;
			return result;
		}
	}

	//get a row feed for the specified url. results are trimmed to simple / single level objects
	function sheetRows(url, callBack, query, rowsPage) {
		//set defaults
		if (!rowsPage) {
			var rowsPage = 750;
		}
		var skip = 1;

		//clean up / initialize query string
		//start with object if passed
		if (query) {
			var query = convertQuery(query);
		}

		//should not pass max and start in the query, but override if there.
		//or create with defaults
		if (!query) {
			//no query specified, so we need to at least have
			var query = 'max-results=' + rowsPage + '&start-index=1';
		} else {
			//we have a query, so we need to append or override the max and offset.
			var m = query.indexOf('max-results=');
			if (m > 0) {
				query = updateParam(query, 'max-results', rowsPage); //if there's a max parm specified already, we override it.
			} else {
				query += '&max-results=' + rowsPage;
			}
			//does the query argument already have a skip?
			var s = query.indexOf('start-index=');
			if (s > 0) {
				var se = query.indexOf('&', s);
				var skip = query.substring(s + 12, se);
			} else {
				var skip = 1; //for sheets we start with one
				query += '&start-index=' + skip;
			}
		}

		//make initial call
		jsonpSend(url, query, convert);

		//callBack will make call recursively if full page is returned
		function convert(result) {
			//convert and return these results to our external callBack
			var newResult = [];
			var a = result['feed']['entry'];
			if (a.length === rowsPage) {
				//we got a full page back, so we need to call again.
				skip = skip + rowsPage;
				query = updateParam(query, 'start-index', skip);
				jsonpSend(url, query, convert);
			} else {
				//update settings worksheet
				var obj = findWorksheet('rowFeed', url);
				obj['postURL'] = result['feed']['link'][2]['href'];
			}

			var i = 0;
			var p = 0;
			var val = '';
			var thisProp = '';
			var props = [];
			for (i in a) {
				props = Object.getOwnPropertyNames(a[i]);
				newResult[i] = {};
				for (p in props) {
					if (props[p].substring(0, 4) === 'gsx$') {
						thisProp = props[p].substring(4);
						if (a[i][props[p]]['$t'].length) {
							newResult[i][thisProp] = a[i][props[p]]['$t'];
						} else {
							newResult[i][thisProp] = '';
						}
					} else if (props[p] === 'link') {
						newResult[i]['googleId'] = a[i]['link'][0]['href'];
						//newResult[i]["editURL"]=a[i]["link"][1]["href"];
					}
				}
			}

			callBack(newResult);
		}
	}

	//Private Sheet Functions******************************************
	//simple JSONP function. puts the callBack into the settings object so it can be accessed from the JSONP callback and not be global.
	function jsonpSend(url, query, callBack) {
		settings.internalCallBack = callBack;
		if (query) {
			url += '?' + query + '&';
		} else {
			url += '?';
		}
		url += 'callback=' + 'gBk.settings.internalCallBack';
		url += '&alt=json-in-script&access_token=' + settings.token;

		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = url;
		document.getElementsByTagName('head')[0].appendChild(script);
		document.getElementsByTagName('head')[0].removeChild(script);
	}

	//updates a parameter value in url query and returns new query string
	function updateParam(query, param, value) {
		var pl = param.length;
		var ql = query.length;
		var pp = query.lastIndexOf(param);
		if (!pp) {
			return;
		} //can only update existing params
		var pe = query.indexOf('&', pp);
		if (pe < 0) {
			pe = ql;
		}
		var q =
			query.substring(0, Number(pp) + Number(pl) + 1) +
			value +
			query.substring(pe, ql);

		return q;
	}

	function convertQuery(requestArray) {
		var ra = 0;

		for (ra in requestArray) {
			if (ra > 0) {
				q += ' or ';
			} else {
				var q = 'sq=';
			}

			var props = Object.getOwnPropertyNames(requestArray[ra]);
			var i = 0;
			for (i in props) {
				if (i > 0) {
					q += ' and ';
				}
				var val = requestArray[ra][props[i]];
				if (val.indexOf('and') > 0) {
					var pos = val.indexOf('and');
					val =
						val.substring(0, pos + 3) +
						' ' +
						props[i] +
						val.substring(pos + 3);
				}
				q += props[i] + val;
			}
		}
		return q;
	}

	function findWorksheet(property, value) {
		var c = 0;
		var i = 0;
		for (c in settings['spreadsheetList']) {
			for (i in settings['spreadsheetList'][c]['worksheets']) {
				if (
					settings['spreadsheetList'][c]['worksheets'][i][
						property
					] === value
				) {
					return settings['spreadsheetList'][c]['worksheets'][i];
				}
			}
		}
		return false;
	}
})(
	(function () {
		function getSharedPropertyValue(eventData, property) {
			if (!eventData || !property || !eventData.extendedProperties) {
				return;
			}

			if (
				eventData.extendedProperties.private &&
				eventData.extendedProperties.private[property]
			) {
				return eventData.extendedProperties.private[property];
			} else if (
				eventData.extendedProperties.shared &&
				eventData.extendedProperties.shared[property]
			) {
				return eventData.extendedProperties.shared[property];
			}
		}
		//begin defining default google settings
		var settings = {
			config: {
				client_id: '',
				scope: '',
				immediate: true, //set to true to attempt token retrieval without the pop-up
			},
			calendarApi: 'v4',
			token: '',
			validtoken: null,
			tokenexpires: 0,

			userinfo: null,
			timezone: '',

			retryLimit: 2,
			retryWait: 500,

			callBack: null,

			internalCallBack: null,

			calendarList: {},

			spreadsheetList: {},

			mapIn: {
				eventID: {
					getValue: function (o) {
						return o.id;
					}, //end method
				}, //end id
				titleEdit: {
					getValue: function (o) {
						return o.summary;
					}, //end method
				}, //end title
				allDay: {
					getValue: function (o) {
						if (!o.start.dateTime) {
							return true;
						}
						return false;
					}, //end method
				}, //end all day
				start: {
					getValue: function (o) {
						if (!o.start.dateTime) {
							return o.start.date;
						}
						return o.start.dateTime;
					}, //end method
				}, //end start
				end: {
					getValue: function (o) {
						if (!o.end.dateTime) {
							return o.end.date;
						}
						return o.end.dateTime;
					}, //end method
				}, //end start
				description: {
					getValue: function (o) {
						if (!o.description) {
							return '';
						}
						return o.description;
					}, //end method
				}, //end description
				creator: {
					getValue: function (o) {
						if (o.creator) {
							if (o.creator.displayName) {
								return o.creator.displayName;
							} else {
								return '';
							}
						}
					}, //end method
				}, //end creator
				email: {
					getValue: function (o) {
						if (o.creator) {
							if (o.creator.email) {
								return o.creator.email;
							} else {
								return '';
							}
						}
					}, //end method
				}, //end email
				location: {
					getValue: function (o) {
						if (o.location) {
							return o.location;
						}
						return '';
					}, //end method
				}, //end location
				geocode: {
					getValue: function (o) {
						var result;
						var value = getSharedPropertyValue(o, 'dBk_geocode');
						if (value) {
							try {
								result = JSON.parse(value);
							} catch (error) {
								result = '';
							}
							return result;
						}
						return '';
					}, //end method
				}, //end status
				resource: {
					getValue: function (o) {
						var result;
						var value = getSharedPropertyValue(o, 'dBk_resource');
						if (value) {
							try {
								result = JSON.parse(value);
							} catch (error) {
								result = '';
							}
							return result;
						}
						return '';
					}, //end method
				}, //end resource
				status: {
					getValue: function (o) {
						var result;
						var value = getSharedPropertyValue(o, 'dBk_status');
						if (value) {
							try {
								result = JSON.parse(value);
							} catch (error) {
								result = '';
							}
							return result;
						}
						return '';
					}, //end method
				}, //end status
				tags: {
					getValue: function (o) {
						var result;
						var value = getSharedPropertyValue(o, 'dBk_tags');
						if (value) {
							try {
								result = JSON.parse(value);
							} catch (error) {
								result = '';
							}
							return result;
						}
						return '';
					}, //end method
				}, //end tags
				done: {
					getValue: function (o) {
						var value = getSharedPropertyValue(o, 'dBk_done');
						if (value) {
							if (value === 'true') {
								return true;
							} else {
								return false;
							}
						}
						return '';
					}, //end method
				}, //end done
				toDos: {
					getValue: function (o) {
						var result;
						var value = getSharedPropertyValue(o, 'dBk_toDos');
						if (value) {
							try {
								result = JSON.parse(value);
							} catch (error) {
								result = '';
							}
							return result;
						}
						return [];
					}, //end method
				}, //end toDos
				additionalDayBackData: {
					getValue: function (o) {
						var result;
						var value = getSharedPropertyValue(
							o,
							'dBk_additionalDayBackData'
						);

						if (value) {
							try {
								result = JSON.parse(value);
							} catch (error) {
								result = '';
							}
							return result;
						}
						return {};
					}, //end method
				}, //end additionalDayBackData
				recurringEventID: {
					getValue: function (o) {
						if (!o.recurringEventId) {
							return '';
						}
						return o.recurringEventId;
					}, //end method
				}, //end recurringEventId
				recurrence: {
					getValue: function (o) {
						if (!o.recurrence) {
							return [];
						}
						return o.recurrence;
					}, //end method
				}, //end recurrence
				reminders: {
					getValue: function (o) {
						if (o.reminders) {
							return o.reminders;
						} else {
							return {};
						}
					}, //end method
				}, //end reminders
				attendees: {
					getValue: function (o) {
						if (o.attendees) {
							return o.attendees;
						} else {
							return [];
						}
					}, //end method
				}, //end attendees
				calendarId: {
					getValue: function (o) {
						if (o.organizer) {
							if (o.organizer.email) {
								return o.organizer.email;
							}
						}
						return '';
					}, //end method
				}, //end calendarId
				colorId: {
					getValue: function (o) {
						return o.colorId;
					}, //end method
				}, //end colorid
				eventURL: {
					getValue: function (o) {
						return o.htmlLink;
					}, //end method
				}, //end eventURL
				custom: {
					getValue: function (o, id) {
						var name = 'dBk_custom_' + id;
						var value = getSharedPropertyValue(o, name);
						if (value) {
							return value;
						}
						return '';
					}, //end method
				}, //end custom
			}, //end mapIn

			mapOut: {
				titleEdit: function (o) {
					return {
						property: 'summary',
						value: o.titleEdit,
					};
				}, //end method
				start: function (o, eventData) {
					if (eventData.allDay) {
						return {
							property: 'start',
							value: {
								dateTime: null,
								date: o.start.format('YYYY-MM-DD'),
							},
						};
					} else if (o.timezone) {
						return {
							property: 'start',
							value: {
								date: null,
								dateTime: o.start.format(),
								timeZone: o.timezone,
							},
						};
					} else {
						return {
							property: 'start',
							value: {
								date: null,
								dateTime: o.start.format(),
							},
						};
					}
				}, //end start
				end: function (o, eventData) {
					if (eventData.allDay) {
						return {
							property: 'end',
							value: {
								dateTime: null,
								date: o.end.format('YYYY-MM-DD'),
							},
						};
					} else if (o.timezone) {
						return {
							property: 'end',
							value: {
								date: null,
								dateTime: o.end.format(),
								timeZone: o.timezone,
							},
						};
					} else {
						return {
							property: 'end',
							value: {
								date: null,
								dateTime: o.end.format(),
							},
						};
					}
				}, //end method
				description: function (o) {
					return {
						property: 'description',
						value: o.description,
					};
				}, //end method
				location: function (o) {
					return {
						property: 'location',
						value: o.location,
					};
				}, //end method
				geocode: function (o, eventData, sharedPropertyType) {
					var value = {};
					value[sharedPropertyType] = {
						dBk_geocode: JSON.stringify(o.geocode),
					};
					return {
						property: 'extendedProperties',
						value: value,
					};
				}, //end method
				allDay: function (o) {
					return {
						property: 'extendedProperties',
						value: {
							private: {
								dBk_allDay: o.allDay,
							},
						},
					};
				}, //end method
				timezone: function (o) {
					return {
						property: 'extendedProperties',
						value: {
							private: {
								dBk_timezone: o.timezone,
							},
						},
					};
				}, //end method
				resource: function (o, eventData, sharedPropertyType) {
					var value = {};
					value[sharedPropertyType] = {
						dBk_resource: JSON.stringify(o.resource),
					};
					return {
						property: 'extendedProperties',
						value: value,
					};
				}, //end method
				status: function (o, eventData, sharedPropertyType) {
					var value = {};
					value[sharedPropertyType] = {
						dBk_status: JSON.stringify(o.status),
					};
					return {
						property: 'extendedProperties',
						value: value,
					};
				}, //end method
				tags: function (o, eventData, sharedPropertyType) {
					var value = {};
					value[sharedPropertyType] = {
						dBk_tags: JSON.stringify(o.tags),
					};
					return {
						property: 'extendedProperties',
						value: value,
					};
				}, //end method
				done: function (o, eventData, sharedPropertyType) {
					var value = {};
					value[sharedPropertyType] = {
						dBk_done: o.done,
					};
					return {
						property: 'extendedProperties',
						value: value,
					};
				}, //end method
				toDos: function (o, eventData, sharedPropertyType) {
					var value = {};
					value[sharedPropertyType] = {
						dBk_toDos: JSON.stringify(o.toDos),
					};
					return {
						property: 'extendedProperties',
						value: value,
					};
				}, //end method
				additionalDayBackData: function (
					o,
					eventData,
					sharedPropertyType
				) {
					var value = {};
					value[sharedPropertyType] = {
						dBk_additionalDayBackData: JSON.stringify(
							o.additionalDayBackData
						),
					};
					return {
						property: 'extendedProperties',
						value: value,
					};
				}, //end method
				recurrence: function (o) {
					return {
						property: 'recurrence',
						value: o.recurrence,
					};
				}, //end method
				reminders: function (o) {
					return {
						property: 'reminders',
						value: o.reminders,
					};
				}, //end method
				attendees: function (o) {
					return {
						property: 'attendees',
						value: o.attendees,
					};
				}, //end method
				custom: function (o, id, sharedPropertyType) {
					var name = 'dBk_custom_' + id;
					var result = {
						property: 'extendedProperties',
						value: {},
					};

					result.value[sharedPropertyType] = {};
					//Store the field prefixed with dBk
					result.value[sharedPropertyType][name] = o[id];

					return result;
				}, //end method
			}, //end mapOut
			mapsOn: true,
		};
		//end defining google settings

		return settings;
	})()
);
